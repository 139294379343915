<template>
  <Loader v-if="load" />
  <div class="data-table  section" v-else>
    <div v-if="category == 16">
      <div class="data-table-row data-table-row-5 data-table-head">
        <p>Назва</p>
        <p>Дата набрання чинності</p>
        <p>Суб'єкт, що подав на реєстрацію</p>
        <p>Реєстраційний номер</p>
        <p>Дата і номер листа</p>
      </div>
      <a
        :href="item.угода"
        target="_blank"
        class="data-table-row data-table-row-5"
        v-for="(item, index) in register"
        :key="index"
      >
        <p>{{ item[Object.keys(item)[1]] }}</p>
        <p>{{ item[Object.keys(item)[2]] }}</p>
        <p>{{ item[Object.keys(item)[3]] }}</p>
        <p>{{ item[Object.keys(item)[4]] }}</p>
        <p>{{ item[Object.keys(item)[5]] }}</p>
      </a>
    </div>
    <div v-else-if="category == 14">
      <div class="data-table-row  data-table-row-5 data-table-head">
        <p>Дата та номер наказу</p>
        <p>Стара адреса</p>
        <p>Нова адреса</p>
        <p>Зміни</p>
        <p>скасування</p>
      </div>
      <a
        :href="item.наказ"
        target="_blank"
        class="data-table-row data-table-row-5"
        v-for="(item, index) in register"
        :key="index"
      >
        <p>{{ item[Object.keys(item)[1]] }}</p>
        <p>{{ item[Object.keys(item)[2]] }}</p>
        <p>{{ item[Object.keys(item)[3]] }}</p>
        <p>{{ item[Object.keys(item)[5]] }}</p>
        <p>{{ item[Object.keys(item)[6]] }}</p>
      </a>
    </div>
    <div v-else>
      <div class="data-table-row data-table-head">
        <p>Дата та номер наказу</p>
        <p>Замовник</p>
        <p>Назва об'єкту</p>
        <p>Адреса об'єкту</p>
        <p>Зміни</p>
        <p>скасування</p>
      </div>
      <a
        :href="item[Object.keys(item)[7]]"
        target="_blank"
        class="data-table-row"
        v-for="(item, index) in register"
        :key="index"
      >
        <p>{{ item[Object.keys(item)[1]] }}</p>
        <p>{{ item[Object.keys(item)[2]] }}</p>
        <p>{{ item[Object.keys(item)[3]] }}</p>
        <p>{{ item[Object.keys(item)[4]] }}</p>
        <p>{{ item[Object.keys(item)[5]] }}</p>
        <p>{{ item[Object.keys(item)[6]] }}</p>
      </a>
    </div>
  </div>
</template>

<script>
  import { onBeforeMount, reactive, ref } from "vue";

  import axios from "axios";

  import Loader from "../global/Loader";

  import dates from "../../store/lists/dates";

  import { useStore } from "vuex";

  export default {
    components: { Loader },

    props: ["category"],

    setup(props) {
      const store = useStore();
      const load = ref(true);

      //Accordion

      const accordion = (event) => {
        let e = event.target.parentElement;

        e.classList.contains("active") ? e.classList.remove("active") : e.classList.add("active");

        setTimeout(() => {
          e.scrollIntoView({ block: "start", behavior: "smooth" });
        }, 200);
      };

      onBeforeMount(() => fetchData());

      //Fetch data
      const register = reactive([]);

      const fetchData = async () => {
        try {
          let get = await axios.get(dates.all[props.category]),
            header = get.data.values[0],
            rows = get.data.values.slice(1);

          for (const row of rows) {
            const formattedRow = {};

            header.forEach((e, i) => {
              formattedRow[e.toLowerCase()] = row[i];
            });

            if (formattedRow.оприлюднення) {
              if (formattedRow.оприлюднення != "Ні") register.push(formattedRow);
            } else {
              register.push(formattedRow);
            }
          }

          load.value = false;
          window.scrollTo(0, 0);
        } catch (e) {
          store.commit("setModal", "error");
          console.log(e);
          load.value = false;
        }
      };

      return { load, accordion, register };
    },
  };
</script>
