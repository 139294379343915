<template>
  <div class="documents section">
    <div class="section-header">
      <div class="section-header--bread">
        <router-link to="/">Головна</router-link>
        <p>|</p>
      </div>
      <h3>{{ category.title }}</h3>
    </div>

    <Main v-if="category.url < 10" :category="category.url" />

    <Budget v-else-if="category.url >= 10 && category.url < 13" :category="category.url" />

    <Register v-else-if="category.url >= 13" :category="category.url" />
  </div>
</template>

<script>
  import { useRoute } from "vue-router";

  import { computed } from "vue";

  import menu from "../store/lists/menu";

  import Main from "../components/documents/Main.vue";
  import Budget from "../components/documents/Budget.vue";
  import Register from "../components/documents/Register.vue";

  export default {
    name: "Documents",

    components: { Main, Budget, Register },

    setup() {
      const route = useRoute();
      const category = computed(() => menu.name.find((e) => e.url.toString() === route.query.category));

      return { category };
    },
  };
</script>
