<template>
  <Loader v-if="load" />

  <div class="accordion" v-else>
    <div class="accordion-item" v-for="(year, index) in years" :key="index">
      <div class="accordion-item-head" @click="accordion($event)">
        <p>{{ year.year }}</p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
          <line x1="25" y1="5" x2="25" y2="45" />
          <line x1="45" y1="25" x2="5" y2="25" />
        </svg>
      </div>

      <div class="accordion-item-body">
        <ul>
          <li v-for="(item, index) in year.data" :key="index">
            <a
              class="document-item"
              :href="getLink(category, item.номер, item.дата || item.прийнято || item.опубліковано, item.id)"
            >
              <p class="number">{{ item.номер }}</p>
              <p v-if="category == 7">Сесія міської ради ({{ item.назва }}) {{ item.дата }}</p>
              <p v-else-if="category == 8">Засідання виконавчого комітету {{ item.дата }}</p>
              <p v-else>{{ item.назва }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { onBeforeMount, reactive, ref } from "vue";

  import axios from "axios";

  import Loader from "../global/Loader";

  import dates from "../../store/lists/dates";
  import { inLink } from "../../components/functions/link";

  import { useStore } from "vuex";

  export default {
    components: { Loader },

    props: ["category"],

    setup(props) {
      const store = useStore();
      const load = ref(true);
      const getLink = (c, n, d, i) => inLink(c, n, d, i);

      //Accordion

      const accordion = (event) => {
        let e = event.target.parentElement;

        e.classList.contains("active") ? e.classList.remove("active") : e.classList.add("active");

        setTimeout(() => {
          e.scrollIntoView({ block: "start", behavior: "smooth" });
        }, 200);
      };

      onBeforeMount(() => fetchData());

      //Fetch data

      const current = new Date().getFullYear();
      const years = reactive([]);

      const fetchData = async () => {
        try {
          let get = await axios.get(dates.all[props.category]),
            header = get.data.values[0],
            rows = get.data.values.slice(1),
            formated = [];

          for (const row of rows) {
            const formattedRow = {};

            header.forEach((e, i) => {
              formattedRow[e.toLowerCase()] = row[i];
            });

            formated.push(formattedRow);
          }

          for (let i = 0; i < 5; i++) {
            let year = current - i;

            // Double
            if (props.category == 2) formated = formated.filter((e) => e.сесія != "-");

            let data = formated.filter((e) => {
              if (e.дата) {
                return e.дата.includes(year);
              } else if (e.прийнято) {
                return e.прийнято.includes(year);
              } else if (e.опубліковано) {
                return e.опубліковано.includes(year);
              }
            });

            if (data.length != 0)
              years.push({ year, data: data.sort((a, b) => parseFloat(b.номер) - parseFloat(a.номер)) });

            if (i == 4) {
              load.value = false;
              window.scrollTo(0, 0);
            }
          }
        } catch (e) {
          store.commit("setModal", "error");
          console.log(e);
        }
      };

      return { load, accordion, getLink, years };
    },
  };
</script>
