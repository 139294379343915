<template>
  <Loader v-if="load" />

  <div class="accordion" v-else>
    <div class="accordion-item" v-for="(structure, index) in structures" :key="index">
      <div class="accordion-item-head" @click="accordion($event)">
        <p>{{ structure.e }}</p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
          <line x1="25" y1="5" x2="25" y2="45" />
          <line x1="45" y1="25" x2="5" y2="25" />
        </svg>
      </div>

      <div class="accordion-item-body">
        <ul>
          <li v-for="(item, index) in structure.data" :key="index">
            <a class="document-item document-item-large" :href="getLink(category, item.номер)">
              <p class="number">{{ item.номер }}</p>
              <p v-if="category == 12">Бюджетний запит</p>
              <p v-else>{{ item.назва }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { onBeforeMount, reactive, ref } from "vue";

  import axios from "axios";

  import Loader from "../global/Loader";

  import dates from "../../store/lists/dates";

  import { inLink } from "../../components/functions/link";

  import { useStore } from "vuex";

  export default {
    components: { Loader },

    props: ["category"],

    setup(props) {
      const store = useStore();
      const load = ref(true);
      const getLink = (c, n, d) => inLink(c, n, d);

      //Accordion

      const accordion = (event) => {
        let e = event.target.parentElement;

        e.classList.contains("active") ? e.classList.remove("active") : e.classList.add("active");

        setTimeout(() => {
          e.scrollIntoView({ block: "start", behavior: "smooth" });
        }, 200);
      };

      onBeforeMount(() => fetchData());

      //Fetch data

      const structures = reactive([]);

      const fetchData = async () => {
        load.value = true;
        try {
          let get = await axios.get(dates.all[props.category]),
            header = get.data.values[0],
            rows = get.data.values.slice(1),
            formated = [];

          for (const row of rows) {
            const formattedRow = {};

            header.forEach((e, i) => {
              formattedRow[e.toLowerCase()] = row[i];
            });

            formated.push(formattedRow);
          }

          dates.structures.forEach((e) => {
            let data = formated.filter((x) => x.підрозділ == e);
            if (data.length != 0) structures.push({ e, data: data.sort((a, b) => a.номер - b.номер) });
          });
          load.value = false;
          window.scrollTo(0, 0);
        } catch (e) {
          store.commit("setModal", "error");
          console.log(e);
          load.value = false;
        }
      };

      return { load, accordion, getLink, structures };
    },
  };
</script>
